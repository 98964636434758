import React from "react"
import ContactPage from "../components/ContactPage"
import Footer from "../components/Footer"
import Layout from "../components/layout"
import Seo from "../components/seo"
export default function contact() {
  return (
    <Layout>
      <Seo title="Contact" />
      <ContactPage />
      <Footer />
    </Layout>
  )
}
